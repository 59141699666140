import { useEffect, useState } from "react";
import Modal from "../Modal";
import storage, { keyNames } from "../../utils/storage";
import project from '../../../package.json';
import { Typography } from "@mui/material";

export default function ChangeLogModal() {
  const { get, set } = storage();
  const [show, setShow] = useState(false);

  const tokens = get(keyNames.tokens);

  const updateShowState = () => {
    const changelog = get(keyNames.changeLog);
    if (tokens) {
      setShow(changelog?.version !== project.version);
    } else {
      set(keyNames.changeLog, { version: project.version });
    }
  };

  useEffect(() => {
    updateShowState();
    const handleStorageChange = (e: StorageEvent) => {
      if (e.key === keyNames.changeLog) {
        updateShowState();
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title={`Novidades da versão ${project.version} 🎉`}
      show={show}
      confirmLabel="Legal"
      onConfirm={() => {
        set(keyNames.changeLog, { version: project.version });
        updateShowState();
      }}
    >
      <Typography dangerouslySetInnerHTML={{ __html: `${project.changelog}` }} />
    </Modal>
  );
}
