import { createTheme } from '@mui/material';
import { outlinedInputClasses } from '@mui/material';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#faac01',
            contrastText: '#000',
        },
        secondary: {
            main: '#111',
            contrastText: '#fff',
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontFamily: 'Fredoka, sans-serif',
                }
            },
            defaultProps: {
                disableElevation: true
            }
        },
        MuiContainer: {
            styleOverrides: {
                root: {
                    background: 'transparent',
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiFormLabel-root': {
                        color: '#fff'
                    },
                    'input::-webkit-calendar-picker-indicator': {
                        filter: 'invert(1)',
                    },
                    fontFamily: 'Fredoka, sans-serif',
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    fontFamily: 'Fredoka, sans-serif',
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    color: '#fff',
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: '#fff',
                },
                root: {
                    color: '#fff',
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: '#faac01',
                    },
                    fontFamily: 'Fredoka, sans-serif',
                    '&.Mui-disabled': {
                        color: '#555 !important'
                    }
                },
            },
        },
        MuiTypography: {
            styleOverrides: {
                h1: { fontWeight: 900 },
                h2: { fontWeight: 900 },
                h3: { fontWeight: 900 },
                h4: { fontWeight: 900 },
                h5: { fontWeight: 900 },
                h6: { fontWeight: 900 },
                root: {
                    fontFamily: 'Fredoka, sans-serif',
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: '#fff',
                    [`&:hover .${outlinedInputClasses.notchedOutline}`]: {
                        borderColor: '#fff',
                    },
                }
            }
        },
        MuiTableHead: {
            styleOverrides: {
                root: {
                    background: '#faac01',
                    color: '#000',
                }
            }
        },
        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    border: '1px solid #111',
                    '& .MuiToggleButton-root': {
                        width: '130px',
                    },
                    '& .MuiToggleButton-root:not(.Mui-selected)': {
                        background: '#000',
                        color: '#fff',
                    },
                }
            }
        }
    }
});

export default theme;