export default function errorMsg(errCode: number, service: string) {
  switch (errCode) {
    case 400:
      return `${service}: Não posso fazer o que pediu, tente novamente. [Erro 400]`;
    case 401:
      return `${service}: Verifique seu token. [Erro 401]`;
    case 403:
      return `${service}: Você não está autorizado a fazer isto. [Erro 403]`;
    case 404:
      return `${service}: Não encontrei o que procura! [Erro 404]`
    default:
      return `${service}: Ocorreu um erro, tente novamente`;
  }
}