type TimestampObject = {
  title: string;
  taskid: string;
  timestamp: number;
  projects: string[];
  branches: string[];
  pivotalProject: number;
}

export default function checkAgeOfHistory(arr: TimestampObject[]): TimestampObject[] {
  const ninetyDaysAgo = new Date().getTime() - (90 * 24 * 60 * 60 * 1000); // 90 days in milliseconds
  return arr.filter(item => item.timestamp > ninetyDaysAgo);
}
