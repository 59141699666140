import React, { useState, useEffect } from 'react';
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Box,
  Typography,
  Button,
  Stack
} from '@mui/material';
import handleHistory from '../../utils/handleHistory';
import storage, { keyNames } from '../../utils/storage';
import dayjs from 'dayjs';
import Modal from '../../components/Modal';
import { FaTrash, FaInfoCircle } from 'react-icons/fa';
import typeOfStory from '../../utils/typeOfStory';
import checkAgeOfHistory from '../../utils/checkAgeOfHistory';
import { toast } from 'react-toastify';
import { sortByTimestamp } from '../../utils/sortList';

type historyObject = {
  timestamp: number,
  title: string,
  taskid: string,
  projects: [],
  branches: [],
  pivotalProject: string,
}

export default function History() {
  const { get, set } = storage();
  const { rmHistory, clearAllHistory } = handleHistory();
  const history = get(keyNames.history);

  useEffect(() => {
    if (history) {
      const newHistory = checkAgeOfHistory(history);
      if (history.length !== newHistory.length) {
        set(keyNames.history, newHistory);
        toast.info(`${history.length - newHistory.length} item(s) com mais de 90 dias deletado(s)!`, { toastId: 'history90wipe' });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [itemSelected, setItemSelected] = useState({
    id: 0,
    name: '',
    showModal: false,
  });
  const [showDeleteAll, setShowDeleteAll] = useState(false);
  const [infoToShow, setInfoToShow] = useState<any>({ show: false });
  const theaders = [
    'Data',
    'ID',
    'Task',
    'Ações',
  ];

  const handleTaskLink = (id: string, idProjPivotal: string) => {
    if (typeOfStory(id) === 'epics') {
      return (
        <Button
          href={`https://www.pivotaltracker.com/epic/show/${id.replaceAll('#', '')}`}
          target="_blank"
          rel="noopener noreferrer"
          color="primary"
        >
          {id}
        </Button>
      )
    }
    if (!idProjPivotal) {
      return (
        <Button
          disabled
          color="primary"
        >
          {id}
        </Button>
      )
    };
    return (
      <Button
        href={`https://www.pivotaltracker.com/n/projects/${idProjPivotal}/stories/${id.replaceAll('#', '')}`}
        target="_blank"
        rel="noopener noreferrer"
        color="primary"
      >
        {id}
      </Button>
    )
  }
  return (
    <>
      <Box className="row pt-4">
        <Box className="col-12">
          <Typography
            variant="h5"
            component="h1"
          >
            {`Histórico ${history?.length > 0 ? `(${history?.length})` : ''}`}
          </Typography>
        </Box>
        <Box className="col-12">
          {history?.length > 0 ? (
            <>
              <TableContainer component={Paper} className="mb-4">
                <Table>
                  <TableHead>
                    <TableRow>
                      {theaders.map((header) => (
                        <TableCell key={header} align={header === 'Ações' ? 'center' : 'left'}>
                          <Typography variant="body1" fontWeight="900">{header}</Typography>
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {sortByTimestamp(history).map((item: historyObject) => (
                      <TableRow key={item.timestamp}>
                        <TableCell>
                          <Typography variant="body1" sx={{ whiteSpace: 'nowrap' }}>
                            {dayjs(item.timestamp).format('DD/MM/YY [às] HH:mm')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          {handleTaskLink(item.taskid, item?.pivotalProject)}
                        </TableCell>
                        <TableCell>
                          <Typography variant="body1" overflow="hidden" sx={{ wordBreak: 'break-word' }}>
                            {item.title}
                          </Typography>
                        </TableCell>
                        <TableCell align="center">
                          <Stack
                            display="flex"
                            flexDirection="row"
                            justifyContent="center"
                            alignItems="center"
                            gap="3px"
                          >
                            <Button
                              type="button"
                              color="info"
                              title="Informações"
                              onClick={() => setInfoToShow({ ...item, show: true })}
                            >
                              <FaInfoCircle size={20} />
                            </Button>
                            <Button
                              type="button"
                              color="error"
                              title="Apagar"
                              onClick={() => {
                                setItemSelected({
                                  id: item.timestamp,
                                  name: item.title,
                                  showModal: true,
                                })
                              }}
                            >
                              <FaTrash size={20} />
                            </Button>
                          </Stack>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
              <Button
                type="button"
                onClick={() => setShowDeleteAll(true)}
                variant="contained"
                startIcon={<FaTrash />}
              >
                Apagar todo o histórico
              </Button>
            </>
          ) : (
            <Typography
              component="p"
              variant="h6"
              textAlign="center"
              color="primary"
            >
              Histórico vazio
            </Typography>
          )}
        </Box>
      </Box>

      <Modal
        show={itemSelected.showModal}
        confirmLabel="Sim"
        cancelLabel="Não"
        onConfirm={() => {
          rmHistory(itemSelected.id);
          setItemSelected((prev) => ({ ...prev, showModal: false }));
        }}
        onCancel={() => setItemSelected((prev) => ({ ...prev, showModal: false }))}
        title="Apagar item"
        text={`Tem certeza que deseja apagar "${itemSelected.name}"?`}
      />

      <Modal
        show={showDeleteAll}
        confirmLabel="Sim"
        cancelLabel="Não"
        onConfirm={() => {
          setShowDeleteAll(false);
          clearAllHistory();
          setTimeout(() => { window.location.reload() }, 1000)
        }}
        onCancel={() => setShowDeleteAll(false)}
        title="Apagar tudo"
        text="Deseja apagar todo o histórico?"
      />

      <Modal
        show={infoToShow.show}
        title={`${infoToShow.taskid} - ${infoToShow.title}`}
        confirmLabel="Fechar"
        onConfirm={() => setInfoToShow((prev: Object) => ({ ...prev, show: false }))}
      >
        <>
          <Typography variant="h5">Projetos usados</Typography>
          {infoToShow?.projects?.length ? (
            <>
              <Typography variant="body1">
                Pivotal: <b>{infoToShow?.projects[0]}</b>
              </Typography>
              <Typography variant="body1">
                Gitlab: <b>{infoToShow?.projects[1]}</b>
              </Typography>
            </>
          ) : null}

          <Typography variant="h5">Branches usadas</Typography>
          {infoToShow?.branches?.length ? (
            <>
              <Typography variant="body1" sx={{ wordBreak: 'break-word' }}>
                Criada: <b>{infoToShow?.branches[0]}</b>
              </Typography>
              <Typography variant="body1">
                Origem: <b>{infoToShow?.branches[1]}</b>
              </Typography>
              <Typography variant="body1">
                Destino: <b>{infoToShow?.branches[2]}</b>
              </Typography>
            </>
          ) : null}
        </>
      </Modal>
    </>
  )
}