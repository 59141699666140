/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  Typography,
  Box,
  Stack,
  TextField,
  MenuItem,
  Button,
  Checkbox,
  FormControlLabel,
  Avatar,
  Alert,
} from '@mui/material';
import Loading from '../../components/Loading';
import { BiGitBranch } from 'react-icons/bi';
import { GoCheckCircle } from 'react-icons/go';
import Modal from '../../components/Modal';
import storage, { keyNames } from '../../utils/storage';
import api, { endpoints } from '../../services/api';
import errorMsg from '../../utils/errorMsg';
import { toast } from 'react-toastify';
import mrType from '../../utils/mrTypes';
import branchName from '../../utils/branchName';
import typeOfStory from '../../utils/typeOfStory';
import handleHistory from '../../utils/handleHistory';
import ModalProgress from '../../components/ModalProgress';
import { ModalHotfix, ModalFix } from '../../components/ModalForm';
import '../../utils/entities';
import { FetchedDataContext } from '../../context/fetchedData';
import {
  CreateMRProps,
  ProjectsPivotalOptions,
  CheckboxProps,
  GitlabProjectSelected,
  InputText,
  GitlabBranches,
  GitlabTaskInfo,
  GitlabCreateMR,
} from '../../utils/entities';
import stopPipeline from '../../utils/stopPipeline';

export default function CreateMR({ goToConfig }: CreateMRProps): JSX.Element {
  const { data } = useContext(FetchedDataContext);
  // MODULES
  const { get } = storage();
  const { gitlab, pivotal, toggl } = api();
  const tokens = get(keyNames.tokens);
  const { addHistory } = handleHistory();
  // STATES
  const form = { bug: 'bug', hotfix: 'hotfix' };
  const [isLoading, setIsLoading] = useState(false);
  const [showFormModal, setShowFormModal] = useState('');
  const [projectSelected, setProjectSelected] = useState('');
  const [createTimer, setCreateTimer] = useState(false);
  const [createMrCheck, setCreateMrCheck] = useState(true);
  const [stopPipe, setStopPipe] = useState(true);
  const [projectGitSelected, setProjectGitSelected] = useState<GitlabProjectSelected>({
    id: '',
    label: '',
    avatar: ''
  });
  const [branchesGitlab, setBranchesGitlab] = useState<Array<string>>([]);
  const [branchesSelected, setBranchesSelected] = useState<GitlabBranches>({
    origin: '',
    epic: '',
  });
  const [taskInfo, setTaskInfo] = useState<GitlabTaskInfo>({
    name: '',
    branchTitle: '',
  });
  const [projectGitSelectedName, setProjectGitSelectedName] = useState('');
  const [taskID, setTaskID] = useState('');
  const [mrSelected, setMrSelected] = useState<any>('');
  const isValid = useRef(true);
  const [status, setStatus] = useState({
    branch: 'initial',
    mr: 'initial',
    stopPipeline: 'initial',
    pivotal: 'initial',
    toggl: 'skipped',
  });
  const [dataFormFix, setDataFormFix] = useState('');
  const [showProgress, setShowProgress] = useState(false);
  const [pivotalProjSelected, setPivotalProjSelected] = useState('');

  // STATUS ON PAGE LOAD
  useEffect(() => {
    if (createMrCheck) {
      setStatus((prev) => ({ ...prev, mr: 'initial' }));
    } else {
      setStatus((prev) => ({ ...prev, mr: 'skipped' }));
    }
    if (createTimer) {
      setStatus((prev) => ({ ...prev, toggl: 'initial' }));
    } else {
      setStatus((prev) => ({ ...prev, toggl: 'skipped' }));
    }
    if (stopPipe) {
      setStatus((prev) => ({ ...prev, stopPipeline: 'initial' }));
    } else {
      setStatus((prev) => ({ ...prev, stopPipeline: 'skipped' }));
    }
  }, [
    createMrCheck,
    createTimer,
    stopPipe,
  ]);

  const checkIfBranchExists = () => {
    if (!taskID) return;
    const searchBranch = branchesGitlab.toString().includes(taskID.replaceAll('#', ''));
    return searchBranch;
  };

  // SERVICE
  useEffect(() => {
    if (!projectGitSelected.id) return;
    setIsLoading(true);
    gitlab.get(endpoints.gitlab.get.branches(projectGitSelected.id)).then((res) => {
      const list = res?.data || [];
      const newList = list.map((e: { name: string }) => (e.name));
      setBranchesGitlab(newList);
    }).catch((err) => {
      toast.error(errorMsg(err?.response?.status, 'Gitlab'), { toastId: 'gitbranches' });
    });
    setIsLoading(false);
  }, [projectGitSelected]);

  // IF TASK EXISTS SAVE THE INFO
  useEffect(() => {
    if (taskID === '') return;
    if (taskID.indexOf('#') === -1) {
      isValid.current = false;
      return;
    }
    setIsLoading(true);
    isValid.current = true;
    const gettingTask = toast.loading('Procurando task...');
    pivotal.get(endpoints.pivotal.get.taskData(projectSelected, taskID)).then((res) => {
      setIsLoading(false);
      const { name } = res?.data;

      if (typeOfStory(taskID) === 'epics') {
        const epicMR = mrType.find((e) => e.id === 99);
        const newBranchName = branchName(taskInfo.name, taskID, epicMR?.prefix ?? '');
        setStatus((prev) => (
          {
            ...prev,
            pivotal: 'skipped',
          }
        ));
        setMrSelected(epicMR);
        setTaskInfo({
          name,
          branchTitle: newBranchName,
        });
      } else {
        setTaskInfo({
          name,
          branchTitle: '',
        });
        setStatus((prev) => (
          {
            ...prev,
            pivotal: 'initial',
          }
        ));
      }

      if (checkIfBranchExists()) {
        toast.update(gettingTask, {
          render: `Já existe uma branch com o ID ${taskID}`,
          type: 'error',
          isLoading: false,
          closeButton: true,
          closeOnClick: true,
          autoClose: 8000,
          toastId: 'branchalreadyexists',
        });
        isValid.current = false;
        return;
      }

      toast.update(gettingTask, {
        render: `Task: ${name}`,
        type: 'success',
        isLoading: false,
        closeButton: true,
        closeOnClick: true,
        autoClose: 8000,
        toastId: 'branchtask',
      });
    }).catch((err) => {
      setIsLoading(false);
      isValid.current = false;
      if (err?.response?.status === 404) {
        toast.update(gettingTask, {
          render: 'Não achei a task. Verifique o projeto selecionado!',
          type: 'error',
          isLoading: false,
          closeButton: true,
          closeOnClick: true,
          autoClose: 5000,
          toastId: 12,
        });
        return;
      }
      toast.update(gettingTask, {
        render: errorMsg(err?.response?.status, 'Pivotal'),
        type: 'error',
        isLoading: false,
        closeButton: true,
        closeOnClick: true,
        autoClose: 5000,
        toastId: 13,
      });
    })
  }, [taskID]);

  useEffect(() => {
    if (!mrSelected.value) return;

    const newBranchName = branchName(taskInfo.name, taskID, mrSelected.prefix);
    setTaskInfo((prev) => ({
      ...prev,
      branchTitle: newBranchName,
    }));
  }, [mrSelected, taskID]);

  const clearForm = () => window.location.reload();
  const handleTaskStatus = () => {
    if (status.pivotal === 'skipped') return;
    pivotal.put(endpoints.pivotal.put.changeStatus(projectSelected, taskID, dataFormFix)).then(() => {
      setStatus((prev) => ({ ...prev, pivotal: 'success' }));
    }).catch((err) => {
      setStatus((prev) => ({ ...prev, pivotal: 'error' }));
      toast.error(`Erro ao alterar status no Pivotal ${err?.response?.status ? `[Erro ${err.response.status}]` : ''}`);
    });
  }

  const createsMR = ({ taskName, branchName }: GitlabCreateMR) => {
    gitlab.post(
      endpoints.gitlab.post.createMR(
        projectGitSelected.id,
        branchName,
        branchesSelected.epic,
        mrSelected.value,
        mrSelected.icon,
        taskName,
        taskID
      )
    ).then(() => {
      setStatus((prev) => ({ ...prev, mr: 'success' }));
      if (!stopPipe) return;
      stopPipeline({
        projectSelectedID: projectGitSelected.id,
        onCompleted: (e) => setStatus((prev) => ({ ...prev, stopPipeline: e })),
      });
    }).catch((err) => {
      setStatus((prev) => ({ ...prev, mr: 'error' }));
      toast.error(`Erro ao criar MR ${err?.response?.status ? `[Erro ${err.response.status}]` : ''}`);
    });

    handleTaskStatus();
  }

  // CREATE TIMER TOGGL
  const handleCreateTimer = () => {
    toggl.post(endpoints.toggl.post.createTimer, {
      start: new Date().toISOString(),
      created_with: "",
      description: `${taskID} - ${taskInfo.name}`,
      billable: false,
      workspace_id: get(keyNames.tokens)?.toggl_workspace,
      duration: -1,
      stop: null
    }).then(() => {
      setStatus((prev) => ({ ...prev, toggl: 'success' }));
    }).catch((err) => {
      toast.error(`Erro ao criar timer. [Erro ${err.response.status}]`);
      setStatus((prev) => ({ ...prev, toggl: 'error' }));
    });
  };

  // ADD ITEM IN HISTORY
  const newItemHistory = () => {
    addHistory({
      title: createMrCheck ? taskInfo.name : 'Sem MR criada',
      taskid: taskID,
      timestamp: new Date().getTime(),
      projects: [pivotalProjSelected, projectGitSelectedName],
      branches: [taskInfo.branchTitle, branchesSelected.origin, branchesSelected.epic],
      pivotalProject: projectSelected,
    });
    if (!createTimer) return;
    handleCreateTimer();
  }

  // CREATES A BRANCH
  const createsBranch = () => {
    setShowProgress(true);
    gitlab.post(
      endpoints.gitlab.post.createBranch(
        projectGitSelected.id,
        taskInfo.branchTitle,
        branchesSelected.origin
      )
    ).then(() => {
      setStatus((prev) => ({ ...prev, branch: 'success' }));
      newItemHistory();
      if (!createMrCheck) {
        handleTaskStatus();
        return;
      };
      createsMR({ taskName: taskInfo.name, branchName: taskInfo.branchTitle });

    }).catch((err) => {
      setStatus((prev) => ({ ...prev, branch: 'error' }));
      toast.error(`Erro ao criar branch ${err?.response?.status ? `[Erro ${err.response.status}]` : ''}`);
    });
  }

  return (
    <>
      <Box
        className="row pt-4"
        component="form"
        onSubmit={(event) => {
          event.preventDefault();
          createsBranch();
        }}
      >
        <Box className="col-12">
          <Typography
            variant="h5"
            component="h1"
          >
            Criar MR
          </Typography>
          <Box margin="10px 0">
            {taskInfo?.name ? (
              <Alert icon={<GoCheckCircle />} severity="success">{`Task: ${taskInfo?.name}`}</Alert>
            ) : null}
          </Box>
        </Box>
        <Box className="col-12 col-xl-4 col-md-6">
          <TextField
            fullWidth
            label="Projetos Pivotal"
            required
            select={Boolean(data.pivotalProjects)}
            value={projectSelected}
            defaultValue=""
            onChange={(e) => setProjectSelected(e.target.value)}
          >
            {data.pivotalProjects.map((option: ProjectsPivotalOptions) => (
              <MenuItem
                key={option.project_id}
                value={option.project_id}
                onClick={() => setPivotalProjSelected(option.project_name)}
              >
                {option.project_name}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box className="col-12 col-xl-4 col-md-6">
          <TextField
            fullWidth
            label="Projetos Gitlab"
            required
            disabled={!projectSelected}
            select
            value={projectGitSelectedName}
            onChange={(e) => {
              setBranchesSelected({ origin: '', epic: '' });
              setProjectGitSelectedName(e.target.value);
            }}
          >
            {data.gitlabProjects.map((item: GitlabProjectSelected) => (
              <MenuItem
                key={item.id}
                value={item.label}
                defaultValue=""
                onClick={() => setProjectGitSelected(item)}
              >
                <Stack direction="row" spacing={1} alignItems="center">
                  <Avatar
                    src={item.avatar}
                    alt={item.label.toUpperCase()}
                    sx={{
                      width: 22,
                      height: 22,
                    }}
                  />
                  <Box component="span">{item.label}</Box>
                </Stack>
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box className="col-12 col-xl-4 col-md-6">
          <TextField
            fullWidth
            label="Origem"
            required
            select
            value={branchesSelected.origin}
            defaultValue=""
            onChange={(e) => setBranchesSelected((prev) => ({ ...prev, origin: e.target.value }))}
            disabled={!projectGitSelected.id}
          >
            {branchesGitlab.map((item) => (
              <MenuItem
                key={item}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box className="col-12 col-xl-4 col-md-6">
          <TextField
            fullWidth
            label="ID da Task"
            required
            helperText={!isValid.current && "ID inválido"}
            error={!isValid.current}
            onChange={(e: InputText) => {
              if (e.target.value === '') setTaskInfo((prev) => ({ ...prev, name: '' }));
              setTaskID(e.target.value);
            }}
            value={taskID}
            autoComplete="off"
            disabled={!branchesSelected.origin}
          />
        </Box>
        <Box className="col-12 col-xl-4 col-md-6">
          {typeOfStory(taskID) === 'epics' ? (
            <TextField
              fullWidth
              label="Tipo de MR"
              defaultValue={mrType.find((type) => type.id === 99)?.label}
              disabled
            />
          ) : (
            <TextField
              fullWidth
              label="Tipo de MR"
              select
              value={mrSelected.label}
              required
              onChange={(e: any) => {
                setMrSelected(e.target.value);
                if (e.target.value.id === 2) {
                  setShowFormModal(form.bug);
                  return;
                };
                if (e.target.value.id === 10) {
                  setShowFormModal(form.hotfix);
                  return;
                };
                setShowFormModal('');
                setDataFormFix('');
              }}
              disabled={!taskID}
            >
              {mrType.map((e: any) => (
                <MenuItem
                  key={e.id}
                  value={e}
                  disabled={e.id === 99}
                >
                  {e.label}
                </MenuItem>
              ))}
            </TextField>
          )}
        </Box>
        <Box className="col-12 col-xl-4 col-md-6">
          <TextField
            fullWidth
            label="Destino"
            required
            select
            value={branchesSelected.epic}
            defaultValue=""
            onChange={(e) => setBranchesSelected((prev) => ({ ...prev, epic: e.target.value }))}
            disabled={!taskID}
          >
            {branchesGitlab.map((item) => (
              <MenuItem
                key={item}
                value={item}
              >
                {item}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Stack
          direction="row"
          justifyContent="flex-end"
        >
          <FormControlLabel
            control={
              <Checkbox
                title="Criar timer Toggl"
                color="primary"
                disabled={!get(keyNames.tokens)?.toggl_workspace || !get(keyNames.tokens)?.toggl}
                checked={createTimer}
                onChange={(e: CheckboxProps) => {
                  setCreateTimer(e.target.checked);
                  if (e.target.checked) {
                    setStatus((prev) => ({ ...prev, toggl: 'initial' }));
                    return;
                  }
                  setStatus((prev) => ({ ...prev, toggl: 'skipped' }));
                }}
              />
            }
            label="Criar timer Toggl"
          />

          <FormControlLabel
            control={
              <Checkbox
                title="Parar pipeline"
                color="primary"
                checked={stopPipe}
                onChange={(e: CheckboxProps) => {
                  setStopPipe(e.target.checked);
                  if (e.target.checked) {
                    setStatus((prev) => ({ ...prev, stopPipeline: 'initial' }));
                    return;
                  }
                  setStatus((prev) => ({ ...prev, stopPipeline: 'skipped' }));
                }}
              />
            }
            label="Parar pipeline"
          />

          <FormControlLabel
            control={
              <Checkbox
                title="Criar MR"
                color="primary"
                checked={createMrCheck}
                onChange={(e: CheckboxProps) => {
                  setCreateMrCheck(e.target.checked);
                  if (e.target.checked) {
                    setStatus((prev) => ({ ...prev, mr: 'initial' }));
                    return;
                  }
                  setStatus((prev) => ({ ...prev, mr: 'skipped' }));
                }}
              />
            }
            label="Criar MR"
          />
        </Stack>
        <Stack
          className="col-12"
          direction="row"
          justifyContent="space-between"
        >
          <Button
            variant="outlined"
            size="large"
            type="reset"
            onClick={clearForm}
          >
            Limpar
          </Button>
          <Button
            variant="contained"
            size="large"
            startIcon={<BiGitBranch />}
            type="submit"
            disabled={!isValid.current}
          >
            Criar MR
          </Button>
        </Stack>

        <Modal
          show={!Boolean(tokens)}
          title="Sem tokens"
          text="Você deve inserir os tokens necessários para o funcionamento do baby_snake"
          confirmLabel="Configurações"
          onConfirm={() => goToConfig()}
        />
      </Box>
      <Loading show={isLoading} />
      <ModalProgress
        {...status}
        show={showProgress}
        setShow={(bool) => setShowProgress(bool)}
      />
      <ModalHotfix
        show={showFormModal === form.hotfix}
        setShow={() => setShowFormModal('')}
        setData={(e: any) => setDataFormFix(encodeURIComponent(e))}
      />
      <ModalFix
        show={showFormModal === form.bug}
        setShow={() => setShowFormModal('')}
        setData={(e: any) => setDataFormFix(encodeURIComponent(e))}
      />
    </>
  )
}