/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useContext } from 'react';
import {
  Box,
  Stack,
  Typography,
  TextField,
  MenuItem,
  Button,
  Avatar,
} from '@mui/material';
import { BiGitCompare, BiTime } from 'react-icons/bi'
import { FetchedDataContext } from '../../context/fetchedData';
import api, { endpoints } from '../../services/api';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import dayjs from 'dayjs';
import {
  GitlabProjectSelected,
  GitlabBranchesResponse,
  GitlabDiffResponse,
} from '../../utils/entities';

export default function BranchDiffs() {
  const { data } = useContext(FetchedDataContext);
  const { gitlab } = api();
  const [form, setForm] = useState({
    project: '',
    from: '',
    to: '',
  });

  const [branches, setBranches] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [commits, setCommits] = useState([{ title: '', id: '', date: '', who: '' }]);

  useEffect(() => {
    if (form.project === '') return;
    setIsLoading(true);
    gitlab(endpoints.gitlab.get.branches(form.project)).then((res: any) => {
      const list = res.data.map((e: { name: string }) => (e.name));
      setBranches(list);
      setIsLoading(false);
    }).catch(() => setIsLoading(false))
  }, [form.project])

  const handleForm = (key: string, data: any) => {
    setCommits([]);
    setForm((prev) => ({
      ...prev,
      [key]: data,
    }))
  }

  const handleCompare = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    gitlab(endpoints.gitlab.get.compare(form.project, form.to, form.from)).then((diff: GitlabDiffResponse) => {
      const listCommits = diff.data?.commits;
      if (!listCommits) return;
      const rawList = listCommits.map((e: GitlabBranchesResponse) => ({
        title: e.title,
        id: e.short_id,
        date: e.committed_date,
        who: e.committer_name,
      }))
      const filteredList = rawList.filter((e) => !e.title.includes('Merge branch '));
      setCommits(filteredList);
      if (filteredList.length === 0) {
        toast.info(`Sem diferenças entre "${form.from}" e "${form.to}"`);
      }
    });
  }

  return (
    <>
      <Box
        className="row pt-4"
        component="form"
        onSubmit={handleCompare}
      >
        <Box className="col-12">
          <Typography
            variant="h5"
            component="h1"
          >
            Branch Diffs
          </Typography>
        </Box>
        <Box
          className="col-12"
          flexWrap="wrap"
        >
          <TextField
            fullWidth
            select
            label="Projeto"
            required
            onChange={(event) => {
              handleForm('project', event.target.value);
              setForm((prev) => ({
                ...prev,
                from: '',
                to: ''
              }))
            }}
            value={form.project}
          >
            {
              data.gitlabProjects.map((p: GitlabProjectSelected) => (
                <MenuItem key={p.id} value={p.id}>
                  <Stack direction="row" spacing={1} alignItems="center">
                    <Avatar
                      src={p.avatar}
                      alt={p.label.toUpperCase()}
                      sx={{
                        width: 22,
                        height: 22,
                      }}
                    />
                    <Box component="span">{p.label}</Box>
                  </Stack>
                </MenuItem>
              ))
            }
          </TextField>
        </Box>
        <Box
          className="col-12 col-md-4"
        >
          <TextField
            fullWidth
            select
            label="De"
            value={form.from}
            required
            onChange={(event) => handleForm('from', event.target.value)}
            disabled={!form.project}
          >
            {branches.map((b) => (
              <MenuItem key={b} value={b}>{b}</MenuItem>
            ))}
          </TextField>
        </Box>
        <Box
          className="col-12 col-md-4"
        >
          <TextField
            fullWidth
            select
            label="Para"
            value={form.to}
            required
            onChange={(event) => handleForm('to', event.target.value)}
            disabled={!form.from}
          >
            {branches.filter((e) => (e !== form.from)).map((b) => (
              <MenuItem key={b} value={b}>{b}</MenuItem>
            ))}
          </TextField>
        </Box>
        <Box
          className="col-12 col-md-4"
          display="flex"
          flexDirection="row"
          alignContent="center"
        >
          <Button
            variant="contained"
            size="large"
            fullWidth
            startIcon={<BiGitCompare />}
            type="submit"
          >
            Comparar
          </Button>
        </Box>
      </Box>
      <Box
        sx={{
          width: '100%',
          borderRadius: '6px'
        }}
        display="flex"
        flexDirection="column"
        border="1px solid #fff"
        padding="20px"
        marginTop="20px"
        gap="10px"
      >
        <Typography variant="h5" gutterBottom>Diferenças {commits?.length > 0 ? commits[0].id !== '' ? `(${commits.length})` : null : null}</Typography>
        <Stack
          direction="column-reverse"
          spacing="10px"
        >
          {commits.map((e: any) => {
            if (e.title === '') return null;
            return (
              <Box key={e.id}>
                <Typography variant="body1" component="p">
                  {e.title.replaceAll('/n', ' ')}
                </Typography>
                <Typography variant="body2" color="#888">
                  <BiTime width="1em" height="1em" spacing={1} />
                  {` ${dayjs(e.date).format('DD/MM/YY [às] HH:mm')} por ${e.who}`}
                </Typography>
              </Box>
            )
          })}
          {commits?.length === 0 ? (
            <Typography variant="body1">Vazio</Typography>
          ) : null}
        </Stack>
      </Box>
      <Loading show={isLoading} />
    </>
  )
}