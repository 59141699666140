import logo from '../assets/logo.svg';
import pivotal from '../assets/pivotal.svg';
import gitlab from '../assets/gitlab.svg';
import toggl from '../assets/toggl.svg';

const icons = {
  logo,
  pivotal,
  gitlab,
  toggl,
}

export default icons;
