import React, { ReactElement, useState } from 'react';
import Modal from '../Modal';
import { DataHotfix } from '../../utils/entities';
import { msgHotfix } from './message';
import {
  Box,
  TextField,
} from '@mui/material';

interface ModalHotfixProps {
  show: boolean;
  setShow: (show: boolean) => void;
  setData: (data: Object) => void;
}

interface FormHotFixProps {
  hotfix: DataHotfix;
  handleChange: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement>;
}
const today = new Date().toISOString().slice(0, 16);
export function FormHotfix({ hotfix, handleChange }: FormHotFixProps) {
  return (
    <Box component="form" display="grid" gridTemplateColumns="repeat(2, 1fr)" gap="1rem">
      <TextField
        label="Data do incidente"
        name="dateIncident"
        value={hotfix.dateIncident}
        type="datetime-local"
        inputProps={{ max: today }}
        onChange={handleChange}
        fullWidth
        margin="dense"
        focused
      />
      <TextField
        label="Data que foi reportado"
        name="dateReport"
        value={hotfix.dateReport}
        type="datetime-local"
        inputProps={{ max: today, min: hotfix.dateIncident }}
        onChange={handleChange}
        fullWidth
        margin="dense"
        focused
      />
      <TextField
        label="Reportado por"
        name="reportedBy"
        value={hotfix.reportedBy}
        onChange={handleChange}
        fullWidth
        margin="dense"
      />
      <TextField
        label="Hospitais impactados? Se sim, quais?"
        name="otherHospitals"
        value={hotfix.otherHospitals}
        onChange={handleChange}
        fullWidth
        margin="dense"
      />
      <TextField
        label="Problema reportado"
        name="problemReported"
        value={hotfix.problemReported}
        onChange={handleChange}
        fullWidth
        margin="dense"
      />
      <TextField
        label="Causa raiz"
        name="rootCause"
        value={hotfix.rootCause}
        onChange={handleChange}
        fullWidth
        margin="dense"
      />
      <TextField
        label="Solução"
        name="solution"
        value={hotfix.solution}
        onChange={handleChange}
        fullWidth
        margin="dense"
      />
      <TextField
        label="Validação"
        name="validation"
        value={hotfix.validation}
        onChange={handleChange}
        fullWidth
        margin="dense"
      />
    </Box>
  )
}

export default function ModalHotfix({ show, setShow, setData }: ModalHotfixProps): ReactElement {
  const [showForm, setShowForm] = useState(false);
  const [hotfix, setHotfix] = useState({
    dateIncident: '',
    dateReport: '',
    reportedBy: '',
    otherHospitals: '',
    problemReported: '',
    rootCause: '',
    solution: '',
    validation: '',
  });

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    if (name === 'dateIncident') {
      setHotfix({
        ...hotfix,
        dateIncident: value,
        dateReport: value,
      });
    } else {
      setHotfix({
        ...hotfix,
        [name]: value,
      });
    }
  };

  const clearForm = () => {
    setHotfix({
      dateIncident: '',
      dateReport: '',
      reportedBy: '',
      otherHospitals: '',
      problemReported: '',
      rootCause: '',
      solution: '',
      validation: '',
    });
  }

  return (
    <>
      <Modal
        title="Preencher formulário"
        text="Você selecionou HOTFIX, deseja preencher o formulário?"
        show={show}
        confirmLabel="Sim"
        cancelLabel="Não"
        onConfirm={() => {
          setShowForm(true);
          setShow(false);
        }}
        onCancel={() => {
          setShow(false);
          clearForm();
        }}
      />
      {showForm ? (
        <Modal
          title="Formulário de Hotfix"
          show={showForm}
          confirmLabel="Enviar"
          cancelLabel="Cancelar"
          onConfirm={() => {
            setData(msgHotfix(hotfix));
            setShowForm(false);
          }}
          onCancel={() => {
            setShowForm(false);
            clearForm();
          }}
        >
          <FormHotfix hotfix={hotfix} handleChange={handleChange} />
        </Modal>
      ) : null}

    </>
  )
}