import storage, { keyNames } from './storage';

type HistoryTimestamp = {
  timestamp: number,
}

export default function handleHistory() {
  const { get, set, removeOne } = storage();
  const history = get(keyNames.history) || [];

  const addHistory = (data: Object) => {
    set(keyNames.history, [...history, data]);
  }

  const rmHistory = (id: number) => { /// note: timestamp is used as an id
    const filtered = history.filter((item: HistoryTimestamp) => item.timestamp !== id);
    set(keyNames.history, filtered);
  }

  const clearAllHistory = () => removeOne(keyNames.history);

  return { addHistory, rmHistory, clearAllHistory }
}