import React from 'react';
import project from '../../../package.json';

type props = {
  children: JSX.Element | JSX.Element[] | any;
}

export default function Container({ children }: props) {
  return (
    <>
      <section className="container main-container">
        {children}
      </section>
      <div className="copyright">baby_snake v{project.version}</div>
    </>
  )
}