/* eslint-disable no-useless-escape */
export default function branchName(taskName: string, taskID:string, prefix: string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/,:;';
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------';
  const p = new RegExp(a.split('').join('|'), 'g');
  const nameSnakeCase = taskName.toString().toLowerCase()
    .replace(/\s+/g, '_') // Troca espaço com: -
    .replace(p, c => b.charAt(a.indexOf(c))) // Troca chars especiais
    .replace(/&/g, 'e') // Troca & com 'e'
    .replace(/[^\w\-]+/g, '') // Remove todos chars que não são palavras
    .replace(/\-\-+/g, '_') // Troca multiplos: - por um: _
    .replace(/^-+/, '') // Trim - no começa do texto
    .replace(/-+$/, '') // Trim - no fim do texto

  return `${prefix}/${taskID.replaceAll('#', '')}-${nameSnakeCase}`;
}