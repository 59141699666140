import { useContext, useEffect } from 'react';
import Container from './components/Container';
import TopBar from './components/TopBar';
import CreateMR from './pages/CreateMR';
import Configurations from './pages/Config';
import History from './pages/History';
import BranchDiffs from './pages/BranchDiffs';
import TasksForms from './pages/TasksForms';
import QrCode from './pages/QrCode';
import { FetchedDataContext } from './context/fetchedData';
import storage, { keyNames } from './utils/storage';
import ChangeLogModal from './components/ChangeLogModal';

function App() {
  const { data, setData } = useContext(FetchedDataContext);
  const { get } = storage();
  useEffect(() => {
    const { pivotal, gitlab } = get(keyNames.tokens) || {};
    if (!gitlab && pivotal) {
      setData((prev: any) => ({ ...prev, pageSelected: 102 }));
    }
    if (gitlab && !pivotal) {
      setData((prev: any) => ({ ...prev, pageSelected: 101 }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <TopBar />
      <Container>
        {data.pageSelected === 100 ? (
          <CreateMR
            goToConfig={() => setData((prev: any) => ({ ...prev, pageSelected: 300 }))}
          />
        ) : null}
        {data.pageSelected === 101 ? (<BranchDiffs />) : null}
        {data.pageSelected === 102 ? (<TasksForms />) : null}
        {data.pageSelected === 200 ? (<History />) : null}
        {data.pageSelected === 201 ? (<QrCode />) : null}
        {data.pageSelected === 300 ? (<Configurations />) : null}
      </Container>
      <ChangeLogModal />
    </>
  );
}

export default App;
