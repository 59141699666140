export default function sortList(keyname: string, array: Array<object>) {
  const compareStrings = (a: any, b: any) => {
    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  const listSorted = array.sort((a: any, b: any) => {
    const itemA = a[keyname as keyof Object].toUpperCase();
    const itemB = b[keyname as keyof Object].toUpperCase();

    return compareStrings(itemA, itemB);
  });

  return listSorted
}

export const sortByTimestamp = (array: any) => {
  const compareTimestamps = (a: any, b: any) => {
    return b.timestamp - a.timestamp;
  };

  const sortedArray = array.sort(compareTimestamps);
  return sortedArray;
};