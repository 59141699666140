/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useState, useEffect } from 'react';
import {
  Stack,
  TextField,
  MenuItem,
  Box,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  Chip,
  Link,
  Button,
} from '@mui/material';
import { FetchedDataContext } from '../../context/fetchedData';
import api, { endpoints } from '../../services/api';
import { toast } from 'react-toastify';
import { BiLinkExternal, BiCommentEdit  } from "react-icons/bi";
import { FormHotfix, FormFix, msgFix, msgHotfix } from '../../components/ModalForm';

export default function TasksForms() {
  const { data } = useContext(FetchedDataContext);
  const [taskToSearch, setTaskToSearch] = useState({
    id: '',
    projectId: '',
  });
  const [hotfix, setHotfix] = useState({});
  const [bug, setBug] = useState({});
  const [taskData, setTaskData] = useState({});
  const [formType, setFormType] = useState('bug');
  const [taskNotFound, setTaskNotFound] = useState(false);
  const { pivotal } = api();

  const handleBugData = (event) => {
    const { name, value } = event.target;
    setBug({
      ...bug,
      [name]: value,
    });
  };

  const handleHotfixData = (event) => {
    const { name, value } = event.target;
    setHotfix({
      ...hotfix,
      [name]: value,
    });
  };

  const sendDescription = () => {
    let description;
    if (formType === 'bug') {
      description = msgFix(bug);
    } else {
      description = msgHotfix(hotfix);
    }
    pivotal.put(endpoints.pivotal.put.description(taskToSearch.projectId, taskToSearch.id, encodeURIComponent(description))).then(() => {
      toast.success('Descrição enviada com sucesso! 🚀');
    }).catch((err) => {
      const { status } = err?.response || {};
      toast.error(`Ocorreu um erro, tente novamente! 😒 ${Boolean(status) ? (`[Erro: ${status}]`) : ''}`);
    });
  };

  useEffect(() => {
    if (!taskToSearch.id) return;
    pivotal.get(endpoints.pivotal.get.taskData(taskToSearch.projectId, taskToSearch.id)).then((response) => {
      setTaskData(response.data);
      setTaskNotFound(false);
    }).catch((err) => {
      const { status } = err?.response || {};
      if (status === 404) {
        toast.error('Task não encontrada, selecionou o projeto correto? 🤔');
      } else {
        toast.error('Ocorreu um erro inesperado, tente novamente. 😮');
      }
      setTaskNotFound(true);
    });
  }, [taskToSearch.id])

  const handleSearch = (event) => {
    const { name, value } = event.target;

    if (name === 'projectId') {
      setTaskToSearch({
        ...taskToSearch,
        [name]: value,
        id: '',
      });
      return;
    }
    setTaskToSearch({
      ...taskToSearch,
      [name]: value,
    });
  };
  return (
    <>
      <Box width={1} marginTop="30px">
        <Typography
          variant="h5"
          component="h1"
        >
          Tasks Forms
        </Typography>
      </Box>
      <Stack
        component="form"
        gap="25px"
        flexDirection="row"
        padding="30px 0"
      >
        <TextField
          value={taskToSearch.projectId || ''}
          select
          label="Projeto pivotal"
          required
          name="projectId"
          onChange={handleSearch}
          fullWidth
        >
          {data.pivotalProjects.map((project) => (
            <MenuItem key={project.project_id} value={project.project_id}>
              {project.project_name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          value={taskToSearch.id}
          label="ID da task"
          required
          name="id"
          onChange={handleSearch}
          autoComplete="off"
          fullWidth
          disabled={!taskToSearch.projectId}
          error={taskNotFound}
        />
      </Stack>
      {taskData.name ? (
        <>
          <Link
            variant="h6"
            href={taskData.url}
            target="_blank"
            rel="noreferrer noopener"
            underline="none"
          >
            {`#${taskData.id} - ${taskData.name} (${taskData.story_type.toUpperCase()})`} <BiLinkExternal />
          </Link>
          {taskData.labels.length ? (
            <Stack direction="row" gap="10px" margin="20px 0">
              <Typography variant="subtitle1">Labels:</Typography>
              {taskData.labels.map((label) => (
                <Chip
                  key={label.id}
                  label={label.name}
                  color="info"
                  variant="outlined"
                />
              ))}
            </Stack>
          ) : null}
          <Stack
            width={1}
            direction="row"
            justifyContent="center"
            margin="20px 0"
          >
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={formType}
              onChange={(event) => {
                setFormType(event.target.value);
                setBug({});
                setHotfix({});
              }}
            >
              <ToggleButton size="large" value="bug">Bug</ToggleButton>
              <ToggleButton size="large" value="hotfix">Hotfix</ToggleButton>
            </ToggleButtonGroup>
          </Stack>
          <Box marginBottom="30px">
            {formType === 'bug' ? (
              <FormFix data={bug} handleChange={handleBugData} />
            ) : (
              <FormHotfix hotfix={hotfix} handleChange={handleHotfixData} />
            )}
          </Box>
          <Box
            display="flex"
            justifyContent="flex-end"
          >
            <Button
              color="primary"
              size="large"
              variant="contained"
              startIcon={<BiCommentEdit  />}
              onClick={() => sendDescription()}
            >
              Enviar
            </Button>
          </Box>
        </>
      ) : null}
    </>
  )
}