import React, { ReactElement } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';

type props = {
  children?: ReactElement,
  show: boolean,
  title: string,
  text?: string,
  confirmLabel: string,
  cancelLabel?: string,
  onConfirm: () => void,
  onCancel?: () => void,
}

export default function Modal({
  children,
  show,
  title,
  text,
  confirmLabel,
  cancelLabel,
  onConfirm,
  onCancel,
}: props): JSX.Element {
  return (
    <Dialog open={show} fullWidth={children ? true : false}>
      {title ? (
        <DialogTitle>{title}</DialogTitle>
      ) : null}
      <DialogContent>
        {text ? (
          <DialogContentText>{text}</DialogContentText>
        ) : null}
        {children}
      </DialogContent>
      <DialogActions>
        {cancelLabel ? (
          <Button
            variant="contained"
            onClick={onCancel}
            color="error"
          >
            {cancelLabel}
          </Button>
        ) : null}
        {confirmLabel ? (
          <Button
            variant="contained"
            onClick={onConfirm}
            color="success"
          >
            {confirmLabel}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}

Modal.defaultProps = {
  cancelLabel: '',
  onCancel: () => { },
}