export default function typeOfStory(id: string) {
  const idSplit = id.split('#')?.length;
  switch (idSplit) {
    case 2:
      return 'stories';
    case 3:
      return 'epics';
    default:
      return 'stories';
  }
}