import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
  Button,
  LinearProgress,
} from '@mui/material';
import {
  GoCircle,
  GoCheckCircle,
  GoCircleSlash,
  GoNoEntry,
} from 'react-icons/go';

type props = {
  branch: string,
  mr: string,
  pivotal: string,
  toggl: string,
  stopPipeline: string,
  show: boolean,
  setShow: (boo: boolean) => void,
}

export default function ModalProgress({ branch, mr, pivotal, toggl, stopPipeline, show, setShow }: props) {
  const [hasEnded, setHasEnded] = useState(false);
  const [endWithError, setEndWithError] = useState(false);

  const statusChanger = (status: string) => {
    switch (status) {
      case 'success':
        return <GoCheckCircle color="#07bc0c" size={20} />;
      case 'error':
        return <GoCircleSlash color="#e74c3c" size={20} />;
      case 'skipped':
        return <GoNoEntry color="#ddd" size={20} />
      default:
        return <GoCircle color="#3498db" size={20} />;
    }
  }

  useEffect(() => { // STOP PROGRESS IF ERROR
    const items = [branch, mr, pivotal, toggl, stopPipeline];

    switch (true) {
      case Boolean(items.find((e) => e === 'error')):
        setHasEnded(true);
        setEndWithError(true);
        break;
      case items.every((e) => e !== 'initial'):
        setHasEnded(true);
        setEndWithError(false);
        break;
      default:
        setHasEnded(false);
        setEndWithError(false);
        break;
    }
  }, [branch, mr, pivotal, toggl, stopPipeline]);

  return (
    <Dialog open={show}>
      {!hasEnded ? (
        <LinearProgress color="info" />
      ) : null}
      <DialogTitle>
        {hasEnded ? 'Finalizado' : endWithError ? 'Oops!' : 'Aguarde!'}
      </DialogTitle>
      <DialogContent>
      <DialogContentText>Status</DialogContentText>
        <Typography gutterBottom display="flex" alignItems="center" gap="10px" width={1}>
          {statusChanger(branch)} Criar branch
        </Typography>
        <Typography gutterBottom display="flex" alignItems="center" gap="10px" width={1}>
          {statusChanger(stopPipeline)} Parar pipeline
        </Typography>
        <Typography gutterBottom display="flex" alignItems="center" gap="10px" width={1}>
          {statusChanger(mr)} Criar MR
        </Typography>
        <Typography gutterBottom display="flex" alignItems="center" gap="10px" width={1}>
          {statusChanger(toggl)} Iniciar timer
        </Typography>
        <Typography gutterBottom display="flex" alignItems="center" gap="10px" width={1}>
          {statusChanger(pivotal)} Alterar status no Pivotal
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="success"
          variant="contained"
          disabled={!hasEnded}
          onClick={() => {
            if (endWithError) {
              setShow(false);
              return;
            }
            window.location.reload();
          }}
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}