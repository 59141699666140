/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, useContext } from 'react';
import {
  AppBar,
  Container,
  Toolbar,
  Typography,
  Box,
  Menu,
  MenuItem,
  Chip,
  Avatar,
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import { BiGitBranch, BiGitCompare, BiDetail, BiHistory, BiCog, BiMenu, BiQr } from 'react-icons/bi';
import icons from '../../utils/icons';
import styles from './topbar.module.scss';
import storage, { keyNames } from '../../utils/storage';
import api, { endpoints } from '../../services/api';
import { toast } from 'react-toastify';
import errorMsg from '../../utils/errorMsg';
import sortList from '../../utils/sortList';
import { FetchedDataContext } from '../../context/fetchedData';
import { PivotalArrayProjects } from '../../utils/entities';

export default function TopBar(): JSX.Element {
  const { data, setData } = useContext(FetchedDataContext);
  interface pivotalResponse {
    data?: {
      username: string,
      initials: string,
      projects: any,
    }
  }
  // MODULES
  const loadOnce = useRef(0);
  const { get, set } = storage();
  const { pivotal, gitlab } = api();
  // STATES
  const [user, setUser] = useState({
    username: '',
    initials: '',
  });
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  // SIDE EFFECTS
  if (get(keyNames.tokenChange)) {
    set(keyNames.tokenChange, false);
    window.location.reload();
  }
  useEffect(() => {
    if (loadOnce.current > 0 || !get(keyNames.tokens)?.pivotal) return;
    if (user.username) return;
    pivotal.get(endpoints.pivotal.get.me).then((res: pivotalResponse) => {
      const {
        username = '',
        initials = '',
        projects,
      } = res?.data || {}; // null check
      sortList('project_name', projects);
      setUser({
        username,
        initials,
      });

      setData((prev: any) => ({
        ...prev,
        pivotalProjects: projects,
      }))

    }).catch((err) => {
      toast.error(errorMsg(err?.response?.status, 'PIVOTAL'));
    });
    loadOnce.current += 1;
  }, []);

  useEffect(() => {
    if (data.gitlabProjects.length > 0 || !get(keyNames.tokens)?.gitlab) return;
    gitlab.get(endpoints.gitlab.get.projects).then((res) => {
      if (!res?.data) return;
      sortList('name', res?.data);
      const listProjects = res.data.map((item: PivotalArrayProjects) => {
        return (
          {
            label: item.name,
            id: item.id,
            avatar: item.avatar_url,
          }
        )
      });
      setData((prev: any) => ({ ...prev, gitlabProjects: listProjects }));
    }).catch((err) => {
      toast.error(errorMsg(err?.response?.status, 'Gitlab'), { toastId: 'gitprojects' });
    });
  }, []);

  // FUNCTIONS
  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const menuOptions = [
    {
      id: 100,
      label: 'Criar MR',
      icon: () => <BiGitBranch size="1em" />,
      disabled: !Boolean(get(keyNames.tokens)?.gitlab) || !Boolean(get(keyNames.tokens)?.pivotal),
    },
    {
      id: 101,
      label: 'Branch Diffs',
      icon: () => <BiGitCompare size="1em" />,
      disabled: !Boolean(get(keyNames.tokens)?.gitlab),
    },
    {
      id: 102,
      label: 'Tasks Forms',
      icon: () => <BiDetail size="1em" />,
      disabled: !Boolean(get(keyNames.tokens)?.pivotal),
    },
    {
      id: 200,
      label: 'Histórico',
      icon: () => <BiHistory size="1em" />,
      disabled: !Boolean(get(keyNames.history)),
    },
    {
      id: 201,
      label: 'QR Code',
      icon: () => <BiQr size="1em" />,
      disabled: false,
    },
    {
      id: 300,
      label: 'Configurações',
      icon: () => <BiCog size="1em" />,
      disabled: false,
    },
  ];

  return (
    <AppBar position="static" color="secondary">
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          {/* BOX MENU MOBILE */}
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' }, alignItems: 'center' }}>
            <IconButton
              onClick={handleOpenNavMenu}
            >
              <BiMenu color="#ddd" size={35} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
            >
              {menuOptions.map((option) => {
                if (option.disabled) return null;
                return (
                  <MenuItem
                    key={option.id}
                    onClick={() => {
                      handleCloseNavMenu();
                      setData((prev: any) => ({
                        ...prev,
                        pageSelected: option.id,
                      }));
                    }}
                    disabled={option?.disabled}
                    selected={data.pageSelected === option.id}
                  >
                    <ListItemIcon>{option.icon()}</ListItemIcon>
                    <ListItemText>{option.label}</ListItemText>
                  </MenuItem>
                )
              })}
            </Menu>

            <img src={icons.logo} alt="Logo baby snake" width="30" height="auto" />
            <Typography
              variant="h6"
              noWrap
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontWeight: 700,
                color: 'inherit',
                textDecoration: 'none',
              }}

            >
              baby_snake
            </Typography>
          </Box>
          {/* BOX MENU DESKTOP */}
          <Box sx={
            {
              flexGrow: 1,
              alignItems: 'center',
              display: { xs: 'none', lg: 'flex' },
              gap: '5px'
            }
          }>
            <img src={icons.logo} alt="Logo baby snake" width="40" height="auto" />
            <Typography
              variant="h5"
              noWrap
              sx={{
                mr: '5px',
                display: { xs: 'none', md: 'flex' },
                fontWeight: 700,
                color: 'inherit',
                textDecoration: 'none',
              }}
            >
              baby_snake
            </Typography>

            {menuOptions.map((option) => {
              if (option.disabled) return null;
              return (
                <Button
                  key={`desktop-${option.id}`}
                  onClick={() => {
                    handleCloseNavMenu();
                    setData((prev: any) => ({
                      ...prev,
                      pageSelected: option.id,
                    }));
                  }}
                  sx={{ my: 2, color: '#fff' }}
                  className={data.pageSelected === option.id ? styles.selected : ''}
                  variant={option.disabled ? 'contained' : 'text'}
                  color="primary"
                  disabled={option.disabled}
                  startIcon={option.icon()}
                >
                  {option.label}
                </Button>
              )
            })}
          </Box>
          {/* AVATAR RIGHT SIDE */}
          <Box sx={{ flexGrow: 0 }}>
            {get(keyNames.tokens)?.pivotal ? (
              <>
                <Chip
                  avatar={
                    <Avatar
                      alt="Pivotal"
                      src={icons.pivotal}
                      sx={{ bgcolor: '#fff' }}
                    />
                  }
                  label={user.username}
                  title="Usuário Pivotal"
                  variant="outlined"
                  color="info"
                  sx={{ display: { xs: 'none', md: 'flex' }, cursor: 'default' }}
                />
                <Chip
                  avatar={
                    <Avatar alt="Pivotal" src={icons.pivotal} />
                  }
                  label={user.initials.toUpperCase()}
                  title="Usuário Pivotal"
                  variant="outlined"
                  color="info"
                  sx={{ display: { xs: 'inline-flex', md: 'none' }, cursor: 'default' }}
                />
              </>
            ) : null}
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  )
}